import { OPENSERARCH_EXPERIMENT_ID } from '@/services/Configuration'
import { log } from '@/services/Log'
import { getCurrentUrl } from './getRefererString'
import { getServerSideAbExperiment } from './getServerSideAbExperiment'
import { getCookieValue } from './getCookieValue'

const fallbackUserId = Array(15).fill(null).map(() => '8').join('')
export type BloomreachConfig = {
  useBloomreach: boolean,
  bloomreachUID: string
  url: string
  useOpenSearch?: boolean
}
export const getBloomreachConfig = (
  ctx?: {
    req?: {
      headers?: Record<string, string | string[] | undefined>
    },
    res?: {
      setHeader?: (name: string, value: string) => void
    }
  },
  providedUseOpenSearch?: boolean,
): BloomreachConfig => {
  const bloomreachUID = getCookieValue(ctx?.req)('_br_uid_2') || fallbackUserId
  const useOpenSearch = typeof providedUseOpenSearch === 'boolean'
    ? providedUseOpenSearch
    : getServerSideAbExperiment({
      ctx,
      cookieName: 'openSearchAB',
      cookieFeature: 'isOpenSearchABEnabled',
      experimentId: OPENSERARCH_EXPERIMENT_ID,
    })
  log('getBloomreachConfig: useOpenSearch', useOpenSearch)
  let url = ''
  if (typeof window !== 'undefined') {
    url = getCurrentUrl(window?.location as unknown as { [index: string]: string })
  }
  return {
    useBloomreach: true,
    bloomreachUID,
    url,
    useOpenSearch,
  }
}
